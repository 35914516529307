.main-container 
{
  flex-direction: row;
}

.radio-buttons 
{
  display: flex;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.custom-radio input 
{
  opacity: 0;
  height: 0;
  width: 0;
}

.radio-btn 
{
  margin: 8px;
  width: 160px;
  height: 185px; 
  border: 2.4px solid transparent;
  display: inline-block;
  border-radius: 8px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 16px #c3c3c367;
  cursor: pointer;
}

.radio-btn > i {
  color: #ffffff;
  background-color: #FFDAE9;
  font-size: 16px;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%) scale(1.6);
  border-radius: 40px;
  padding: 2.4px;
  transition: 0.5s;
  pointer-events: none;
  opacity: 0;
}

.radio-btn .hobbies-icon 
{
  width: 110px; 
  height: 110px; 
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio-btn .hobbies-icon img
{
  display:block;
  width:100%;
  margin-bottom:16px;
}
.radio-btn .hobbies-icon i 
{
  color: #FFDAE9;
  line-height: 64px;
  font-size: 40px;
}

.radio-btn .hobbies-icon h4
{
  color: rgb(214, 214, 214);
  font-size: 12px; 
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing:0.8px; 
}

.custom-radio input:checked + .radio-btn 
{
  border: 1.6px solid #FFDAE9;
}

.custom-radio input:checked + .radio-btn > i 
{
  opacity: 1;
  transform: translateX(-50%) scale(0.8);
}

@keyframes pulse {
  0%,
  100% {
     box-shadow: 0 0 0 0 rgba(173, 216, 230, 0.4);
  }
  25% {
     box-shadow: 0 0 0 10px rgba(173, 216, 230, 0.15);
  }
  50% {
     box-shadow: 0 0 0 20px rgba(173, 216, 230, 0.55);
  }
  75% {
     box-shadow: 0 0 0 10px rgba(173, 216, 230, 0.25);
  }
}

.pulse-animation-1 {
  animation: pulse 1.5s infinite ease-in-out;
  border-radius: 8px;
}

.pulse-animation-2 {
  animation: pulse 2.2s infinite ease-in-out;
  border-radius: 8px;
}