header {
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo-container {
  text-align: center;
  width: 100%;
}

.auth-container {
  position: absolute;
  right: 3vw;
}
