@keyframes audio-wave {
  0%{
    height: 10px;
    trnsform: translateY(0px);
    background: #1F4FCC;
  }
  25%{
    height: 40px;
    trnsform: translateY(-5px);
    scaleY: 1.7;
    background:#6785D3;
  }
  50%{
    height: 10px;
    trnsform: translateY(0px);
    scaleY: 1.7;
    background: #C2D3FF;
  }
  100%{
    height: 10px;
    trnsform: translateY(0px);
    scaleY: 1.7;
    background:fixed #EEF3FF;
  }
}


.sound-wave{
  display:flex;
  justify-content: center;
  align-items:center;
  gap:8px;
  height:60px
}
.sound-wave span{
  height:18px;
  width:10px;
  display:block;
  border-radius:8px;
  background:#BEC5D9;
  animation:audio-wave 2.2s infinite ease-in-out
}
.stop-animation span {
  animation-play-state: paused;
}
.sound-wave span:nth-child(2) {
  left:11px;
  background:#FFFFFF;
  animation-delay:0.2s
}
.sound-wave span:nth-child(3){
  left:22px;
  animation-delay:0.4s
}
.sound-wave span:nth-child(4){
  left:33px;
  animation-delay:0.6s
}
.sound-wave span:nth-child(5){
  left:44px;
  animation-delay:0.8s
}
.sound-wave span:nth-child(6){
  left:55px;
  animation-delay:1s
}

.audio-player {
    display: flex;
}

.hidden {
  display: none;
}

.options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  bottom: 0;
  width: 100%;
}

.call-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}